import { message, Table, Space, Upload as AntUpload, Button } from 'antd';
import { CloudUploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { uploadFile } from '../../api/resume';
import useFetchFiles from './hooks/fetch-files';
import { fileDownload } from './hooks/download-files';

const { Dragger } = AntUpload;

async function customRequest({ file, onSuccess, onError }) {
  const hide = message.loading('ファイルをアップロードしています');

  try {
    if (file.size > 2000000) {
      message.error('ファイルサイズが大きすぎます');
      return false;
    }
    await uploadFile(file);
    onSuccess();
  } catch (e) {
    onError();
  } finally {
    hide();
  }
}

function onChange(info, pageChange) {
  if (info.file.status === 'done') {
    message.success('ファイルがアップロードされました');
    pageChange(1);
  }

  if (info.file.status === 'error') {
    message.error('ファイルのアップロードに失敗しました');
  }
}

const columns = [
  {
    title: 'ファイル名',
    dataIndex: 'fileName',
    key: 'fileName',
  },
  {
    title: '登録日',
    dataIndex: 'registrationDate',
    key: 'registrationDate',
  },
  // {
  //   title: '',
  //   key: 'download',
  //   render: ({ attachmentId, fileName }) => (
  //     <Button
  //       icon={<DownloadOutlined />}
  //       type="primary"
  //       onClick={() => fileDownload(attachmentId, fileName)}
  //     >
  //       ダウンロード
  //     </Button>
  //   ),
  // },
];

export default function Upload({ onError }) {
  const { files, pagination, pageChange, filesLoading } = useFetchFiles({
    onError,
  });

  return (
    <>
      <p>
        職務経歴書をお持ちの方は、こちらからアップロードすることも可能です。
        <br />
        ※1ファイルずつアップロードしてください。
      </p>
      <div>
        <Space direction="vertical" className="fluid" size="middle">
          <Dragger
            name="file"
            showUploadList={false}
            customRequest={customRequest}
            onChange={info => onChange(info, pageChange)}
          >
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p className="ant-upload-text">
              クリックしてファイルを選択するか、ファイルをドラッグ＆ドロップしてください。
            </p>
            <p className="ant-upload-hint">
              アップロードできるファイルは2MBまでです。
            </p>
          </Dragger>
          <p>
            2023/4/10
            11:00以降に本ページからアップされたファイルはファイル名、登録日が表示されます。
            {/* <br />
            ダウンロードして内容をご確認いただけます。 */}
          </p>
        </Space>
        <Table
          columns={columns}
          dataSource={files}
          pagination={{
            defaultCurrent: pagination.currentPage,
            total: pagination.total,
            onChange: pageChange,
          }}
          loading={filesLoading}
        />
      </div>
    </>
  );
}
